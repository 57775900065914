<template>
    <div class="content_container findpw">
        <h1>비밀번호 찾기</h1>
        <div class="con_signin">
            <div class="center">
                <div class="fill">
                    <label for="email">이메일</label>
                    <input
                        type="email"
                        id="email"
                        placeholder="이메일 주소"
                        v-model="email"
                        @keyup.enter="submit"
                    />
                </div>

                <button id="btn_signin" type="button" @click="submit">찾기</button>
            </div>
        </div>
    </div>
    <SubFooter />
</template>

<script>
import SubFooter from "@/components/SubFooter";
import { postData } from "@/utils/postData";

export default {
    components: {
        SubFooter,
    },
    mounted() {
        if(document.getElementById("email")){
            document.getElementById("email").focus();
        }
    },
    data() {
        return {
            email: "",
        };
    },
    mixins: [postData],
    methods: {
        // 이메일 입력창에서 엔터시
        emailEnter() {
            this.submit();
        },
        // 이메일로 링크 전송
        submit() {
            this.postData('/verify', {
                email : this.email,
            }, ()=>{
                this.$store.commit("alert", "이메일로 발송된 링크를 통해 비밀번호를 변경해주세요. 해당 링크는 30분간 유효합니다");
                document.getElementById("btn_signin").innerText = "다시보내기";
            })
        },
    },
};
</script>

<style scoped src="@/assets/css/layout_login.css"></style>
